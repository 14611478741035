import {useState,useEffect,useRef} from "react";
import React from "react";
import axios from "axios";
import Checkbox from '@material-ui/core/Checkbox/Checkbox';
import { CircularProgress, Button } from '@material-ui/core';
//import ButtonGroup from '@material-ui/core/ButtonGroup';
//import {Link,useHistory } from 'react-router-dom';

//import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
/**/
import CreditCardIcon from '@mui/icons-material/CreditCard';
import UploadIcon from '@mui/icons-material/Upload';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {useParams} from "react-router-dom";
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';
import Input from '@mui/material/Input';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
//import Select from '@material-ui/core/Select';

import {  InputLabel, Select, MenuItem, TextField } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

//import InputLabel from '@mui/material/InputLabel';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/';
import {  LocalizationProvider } from '@mui/x-date-pickers';
//import bgLocale from 'date-fns/locale/bg';
//import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
//import { createTheme, ThemeProvider } from '@mui/material/styles';
//import Moment from 'react-moment';
//import 'moment-timezone';

import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import InputAdornment from '@mui/material/InputAdornment';

//import CardContent from '@mui/material/CardContent';
//import CardActions from '@mui/material/CardActions';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//import MoreVertIcon from '@mui/icons-material/MoreVert';


import {useNavigate} from 'react-router-dom';
//import MuiPhoneNumber from 'mui-phone-number';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { makeStyles } from "@material-ui/core/styles";
import { useTheme, useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';


const useStyles = makeStyles((theme) => ({
  input: {
    background: "rgb(255, 192, 203)",
    maxLength:"4",
    size:"6"
  },
  input1: {
    background: "rgb(241, 53, 109)",
    maxLength:"4",
    size:"6"
  },
  error: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'red',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'red',
      },
    },
  },

}));


const CreateRegistration=() => {
  //const {section} = useParams();
  const secondBoxRef = useRef();
  const alertRef = useRef();

  //console.log(section);
  const initialValues = {president:"",orgname:"",country:"",
      code:"",fname:"",lname:"",dateofbirth:null,email:"",phone: ""
      ,section:"Senior Ladies Hair" ,idfile:"",diplomafile:"",payLaterCode:"",payDiscountCode:""
    };

    // const initialValues = {president:"",orgname:"",country:"Albania",
    //     code:"",fname:"",lname:"",email:"",phone: ""
    //     ,section:section ,idfile:"",diplomafile:""
    //   };
  //Selected Tests. Used for Pricing
  const [selectedTests,setSelectedTests]=useState([]);
  //FormValues
  const [formValues,setFormValues] = useState(initialValues);
  const [formErrors,setFormErrors] = useState([]);

  //Boolean indicators of From State
  const [isSubmit,setIssubmit] = useState(false);
//Pay
  const [isPay,setIsPay] = useState(false);

  const [isCountryLoading,setIsCountryLoading] = useState(false);
  const [isLoading,setIsLoading] = useState(false);
  const [validated,setValidated] = useState(false);

  const [loadingmessage,setLoadingmessage]=useState("Loading...Please wait");

  const [registrationId,setregid]= useState(null);
  const [successUpload,setSuccessUpload] = useState(false);

  const [photofiles, setPhotoFiles] = useState([]);

  //Accepted file types for online submition
  const types = ['image/png', 'image/jpeg','image/jpg','image/gif','image/tiff'];
  const maxfilesize="10000000";
  const [countries, setCountries] = useState({});
  //const [value, setValue] = React.useState(new Date());

  const [newCategories,setNewcategories]=useState([{}]);

  const [isChecked, setIsChecked] = useState(false);
  const [allowChecked, setAllowChecked] = useState(false);


  const [payLater, setpayLater] = useState(false);
  const [payDiscount, setpayDiscount] = useState(false);

  //code for initial opening of te form
  const [codeAccepted,setCodeAccepted] = useState(false);
  const [codeValid,setCodeValid] = useState(false);
  const [storedCode, setStoredCode] = useState('');
  const [storedCountry, setStoredCountry] = useState('');



  const [selcountrycodes,setSelcountrycodes]=useState([]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate=useNavigate();
  const classes = useStyles();

  const inputStyle = {
   borderColor: formErrors.some(error => error.name === "phone") ? 'red' : '',
  };

  const checkHandler = () => {
    setIsChecked(!isChecked)

  }

  const allowCheckHandler = () => {
    setAllowChecked(!allowChecked)

  }

    const payLaterChange = () => {
      setpayLater(!payLater)
    }

    const payDiscountChange = () => {
      setpayDiscount(!payDiscount)
    }

    // const  calculateTotalPrice = () => {
    // console.log(specialCodeInserted,payDiscount,totalPrice);
    //  return (specialCodeInserted&&payDiscount)?totalPrice/2:totalPrice;
    // }

const payLaterCodeChange = (e) =>{
  const { name, value } = e.target;
  setFormValues({ ...formValues,[name]: value});
//  console.log(formValues.specialCode,payLater,payDiscount);
}

const payDiscountCodeChange = (e) =>{
  const { name, value } = e.target;
  setFormValues({ ...formValues,[name]: value});
//  console.log(formValues.specialCode,payLater,payDiscount);
}

//TODO: Array length should be = total number of tests. Otherwise, 2 dim, Category-Test// BEST keep testid,checked on list, push/empty/getall
  const [checkedState, setCheckedState] = useState(
    new Array(100).fill(false)
);

const [totalPrice, setTotalPrice] = useState(0);
/*Dialog for Code*/
  const [open, setOpen] = React.useState(false);
  //const [selectedValue, setSelectedValue] = React.useState(codeAccepted);


  const handleClickOpen = async () => {
    setIsLoading(true);


    const ORG_API_URL = process.env.REACT_APP_BACKEND_URL+"/services/organisation";

    const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'plain/text');

    const params={code:formValues.code};
    const queryString = new URLSearchParams(params).toString();
    //console.log(queryString);

    let res = await fetch(ORG_API_URL+"?"+queryString).then(async response=>{
    if(!response.ok){//Error on server
          setFormValues({ ...formValues,code:"",president:"",orgname:"",country:""});
          alert('Error contacting server.Check your connection and try again later. Error fetching code: '+response);
          //console.log(res);
          setIsLoading(false);
    }
    else { //fetch ok
      //console.log(response);
      let res1 = await response.json()
      .then(data=> {setFormValues({ ...formValues,president:data.president,orgname:data.name,country:data.country});
                          setIsLoading(false);setOpen(true);setStoredCountry(data.country);
                          //console.log(data);
                        //  setSelcountrycodes(countries.filter(country=> country.name===data.country).map(filt=>{return filt.iso2Code.toLowerCase()})[0]);
                        })
      //Code does not exist, fetch returns null
      .catch(err=>{//Code is Not Valid
        setFormValues({ ...formValues,code:"",president:"",orgname:"",country:""});
        //alert('Error contacting server.Check your connection and try again later. Error fetching code: '+err);
      setIsLoading(false);setOpen(true);});
    }
    })
    .catch (err=>{alert('Error contacting server.Check your connection and try again later.'+err);setIsLoading(false);})

    //process.env.REACT_APP_BACKEND_URL+"/services/registration?regid="+registrationId
  };


  const handleCloseYes = (value: string) => {
    //Disselect tests so price is recalculated
    checkedState.fill(false);
    //Empty photofiles. Only one category is alllowed
    setPhotoFiles([]);
    setTotalPrice(0);
    setOpen(false);
    setCodeAccepted(true);
    setCodeValid(true);
    setStoredCode(formValues.code);

    // window.scrollTo({
    //   top: secondBoxRef.current.offsetTop+10,
    //   behavior: 'smooth'});

  };
  const handleCloseNo = (value: string) => {
    setOpen(false);
    setCodeAccepted(false);
    setCodeValid(false);
    checkedState.fill(false);
    //Empty photofiles. Only one category is alllowed
    setPhotoFiles([]);
    setTotalPrice(0);
    setFormValues({ ...formValues,code:"",president:"",orgname:"",country:""});
    setStoredCode("");

  };

  const handleCloseDis = (value: string) => {
    setCodeAccepted(false);
    setCodeValid(true);
    checkedState.fill(false);
    //Empty photofiles. Only one category is alllowed
    setPhotoFiles([]);
    setTotalPrice(0);
    setFormValues({ ...formValues,code:"",president:"",orgname:"",country:""});
    setStoredCode("");


  };

  useEffect(async () => {
    //console.log(checkedState);
    let data;
    setIsCountryLoading(true);

    const API_URL1 = process.env.REACT_APP_BACKEND_URL+"/services/countries";
    const response = await axios.get(API_URL1, data).then( response=>{
    //console.log(response);
      return response.data;}).then(data=>
        {
        setCountries(data);
        //console.log(data);
    }).catch(err=>{
      //console.log(err);
      alert("Error contacting server.Check your connection and try again later. " + err );

      //console.log(err);
    })

    const API_URL2 = process.env.REACT_APP_BACKEND_URL+"/services/generalCategories";
    const responsecat = await axios.get(API_URL2, data).then( responsecat=>{
      return responsecat.data;}).then(datacat=>
        {//Use Params SEction is ok
          //console.log(datacat);

      //    if (datacat.map(m =>{return m.name}).indexOf(section)===-1){
      //      navigate("/NotFoundPage");
      //    }
      //    else
          setNewcategories(datacat);
        //  console.log(datacat);

    }
  ).catch(err=>{
        alert("Error contacting server.Check your connection and try again later. " + err);
      })
      setIsCountryLoading(false);


      //console.log(isLoading+" "+isCountryLoading);
   },[formErrors]);


const uploadFile3 = async (filetoup,filename,dirname) => {

    let res;
    setLoadingmessage("Uploading file: "+filename+ " for test "+dirname);
    const data = new FormData();
    data.append("file", filetoup);
    data.append("filename",filename);
    data.append("dirname",dirname);

    //console.log(formValues.idfile);
    const F_URL = process.env.REACT_APP_BACKEND_URL+"/services/files";
    //console.log(F_URL);
    try{
      res = await fetch(
      F_URL,
        {
          method: 'put',
          body: data,
          // headers: {}
        })
      //console.log(res);
    } catch (err) {
        //console.log(error);
        alert("Error while uploading files. Check your connection and submit your registration again. " + err );
        return false;
      }
      return res.ok;
  }

//Insert Code input
const codeChange = (e) =>{
  const { name, value } = e.target;
  setFormValues({ ...formValues,[name]: value});
}
//Function to "hear" Enter key button on code insert
const handleKeypress = (e) =>{
//console.log(e.key);
   if (e.key === "Enter" ) {
     document.getElementById("codebutton").click();
   }
 }

//Form Changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    //console.log(e.target);
    //console.log("Changed"+e.target.name+":"+e.target.value);

  //  if(e.target.name==='lname' ||e.target.name==='fname' ||e.target.name==='email'){
    //Trim leading & trailing white spaces

    //When Change Section make all checked tests false
    if(e.target.name==='section'){
        checkedState.fill(false);
        //Empty photofiles. Only one category is alllowed
        setPhotoFiles([]);
        setTotalPrice(0);
    }
    if (e.target.name ==='idfile'|| e.target.name ==='diplomafile')
     {//TODO: SETFORMERRORS
       //console.log(e.target.files[0]);
      // if (e.target.files[0]){
            //console.log(formValues);
            //console.log(e.target.name);
            //console.log(e.target.files[0]);
            let selected=e.target.files[0];

           //Everything is OK
             if (selected&&types.includes(selected.type) && selected.size<maxfilesize)
             {
               setFormValues({ ...formValues,[name]: selected});

             }//File too large
            else if (selected && selected.size>maxfilesize) {
               e.target.value='';
               setFormValues({ ...formValues,[name]: ''});
               //setFile('');
               alert('File too large, please upload file less than '+maxfilesize/1000000+'MB');
               //return {<Alert severity="error">Accepted file types: {types} Max file size: 5MB </Alert>}
             }//Wrong file type
            else if (selected&&!types.includes(selected.type))
            {
              e.target.value='';
              setFormValues({ ...formValues,[name]: ''});
              alert('Accepted file types:'+ types);
            }
    //  }
    e.target.value = '';

       }
      else if (e.target.name ==='fname'|| e.target.name ==='lname'){
        //console.log("Changed"+e.target.name+":"+e.target.value.toUpperCase());

        setFormValues({ ...formValues,[name]: value.toUpperCase()});

      }
      else {
      //  console.log("Changed"+e.target.name+":"+e.target.value);
        //console.log("Trimmed"+e.target.name+":"+e.target.value.trim());
        //if(e.target.name==='lname' ||e.target.name==='fname' ||e.target.name==='email')
        //setFormValues({ ...formValues,[name]: value.trim()});
        //else
        setFormValues({ ...formValues,[name]: value});
       }

    };

    //FileToUpload Changes: Only for online sections
    const handleChangeFile=(id,section,number_photos)=>(e) =>{

        let selected = e.target.files[0];
        let filename=e.target.name;


        //Everything is OK
          if (selected&&types.includes(selected.type) && selected.size<maxfilesize)
          {
          //  console.log(e.target.name,e.target.files[0]);
            if (e.target.name==='frontfile')
            setPhotoFiles(
              photofiles.map((ph) =>
                   // Here you accept a id argument to the function and replace it with hard coded 🤪 2, to make it dynamic.
                   ph.id === id
                       ? { ...ph, frontfile: e.target.files[0] }
                       : { ...ph }
               )
             );

            else if (e.target.name==='sidefile')
            setPhotoFiles(
              photofiles.map((ph) =>
                   // Here you accept a id argument to the function and replace it with hard coded 🤪 2, to make it dynamic.
                   ph.id === id
                       ? { ...ph, sidefile: e.target.files[0] }
                       : { ...ph }
               )
             );
             else if (e.target.name==='sidefile3')
             setPhotoFiles(
               photofiles.map((ph) =>
                    // Here you accept a id argument to the function and replace it with hard coded 🤪 2, to make it dynamic.
                    ph.id === id
                        ? { ...ph, sidefile3: e.target.files[0] }
                        : { ...ph }
                )
              );
              else if (e.target.name==='sidefile4')
              setPhotoFiles(
                photofiles.map((ph) =>
                     // Here you accept a id argument to the function and replace it with hard coded 🤪 2, to make it dynamic.
                     ph.id === id
                         ? { ...ph, sidefile4: e.target.files[0] }
                         : { ...ph }
                 )
               );


          }//File too large
         else if ( selected&& selected.size>maxfilesize) {
            e.target.value='';
            if (e.target.name==='frontfile')
            setPhotoFiles(
              photofiles.map((ph) =>
                   ph.id === id
                       ? { ...ph, frontfile: '' }
                       : { ...ph }
               )
             );

            else if (e.target.name==='sidefile')
            setPhotoFiles(
              photofiles.map((ph) =>
                   ph.id === id
                       ? { ...ph, sidefile: '' }
                       : { ...ph }
               )
             );
             else if (e.target.name==='sidefile3')
             setPhotoFiles(
               photofiles.map((ph) =>
                    ph.id === id
                        ? { ...ph, sidefile3: '' }
                        : { ...ph }
                )
              );
              else if (e.target.name==='sidefile4')
              setPhotoFiles(
                photofiles.map((ph) =>
                     ph.id === id
                         ? { ...ph, sidefile4: '' }
                         : { ...ph }
                 )
               );


            alert('File too large, please upload file less than '+maxfilesize/1000000+'MB');
          }//Wrong file type
           else if (selected&&!types.includes(selected.type)){
           e.target.value='';
           if (e.target.name==='frontfile')
           setPhotoFiles(
             photofiles.map((ph) =>
                  // Here you accept a id argument to the function and replace it with hard coded 🤪 2, to make it dynamic.
                  ph.id === id
                      ? { ...ph, frontfile: '' }
                      : { ...ph }
              )
            );

           else if (e.target.name==='sidefile')
           setPhotoFiles(
             photofiles.map((ph) =>
                  // Here you accept a id argument to the function and replace it with hard coded 🤪 2, to make it dynamic.
                  ph.id === id
                      ? { ...ph, sidefile: '' }
                      : { ...ph }
              )
            );
            else if (e.target.name==='sidefile3')
            setPhotoFiles(
              photofiles.map((ph) =>
                   // Here you accept a id argument to the function and replace it with hard coded 🤪 2, to make it dynamic.
                   ph.id === id
                       ? { ...ph, sidefile3: '' }
                       : { ...ph }
               )
             );
             else if (e.target.name==='sidefile4')
             setPhotoFiles(
               photofiles.map((ph) =>
                    // Here you accept a id argument to the function and replace it with hard coded 🤪 2, to make it dynamic.
                    ph.id === id
                        ? { ...ph, sidefile4: '' }
                        : { ...ph }
                )
              );
          // setFormValues({ ...formValues,[name]: ''});
           alert('Accepted file types:'+ types);
         }
         e.target.value = '';

        //setPhotoFiles(list1);
    };

  const handleCheckTest=(e,secname,position,mustUpload,combinationCategory)=>{


   const updatedCheckedState = checkedState.map((item, index) =>
     index === position ? !item : item);

   setCheckedState(updatedCheckedState);
  // console.log(checkedState.map((item, index) =>index === position ? !item : item));
  //  console.log(newCategories.filter((section=> (section.name===secname))).map(x=> x.testResponses.map((test,i)=>{
  //    if (test.combinationCategory===combinationCategory&&test.testId!==position)  {
  //      return test.testId}
  //    })));

   let newList=[];
   if (e.target.checked && mustUpload){
     //append to list
  //  console.log("Checked");
    newList = photofiles.concat({'sectionname':secname,'id': position,
    testName:newCategories.filter((section=> (section.name===secname))).map(x=> x.testResponses.map((test,i)=>{ if (test.testId===position)  {return test.testName}}))[0].toString().replace(/,/g,"")
    ,photonumber:mustUpload,'frontfile':'','sidefile':'','sidefile3':'','sidefile4':''});
      setPhotoFiles(newList);


    //console.log(newCategories.filter((section=> (section.name===secname))).map(x=> x.testResponses.map((test,i)=>{ if (test.testId===position)  {return test.testName}}))[0].toString().replaceAll(",",""));


  } else if (mustUpload){
     //remove from list
     const newList = photofiles.filter((item) => item.id !== position);
     //console.log(photofiles.filter((item) => item.id !== position));
    setPhotoFiles(newList);
  }


  //Selected Tests
  let tests=[];
  for (let i = 0; i < updatedCheckedState.length; i++) {
      if (updatedCheckedState[i] === true) {
          tests.push(i);
      }
  }
  setSelectedTests(tests);
  let tr =
  newCategories.filter((section=> (section.name===formValues.section))).map(x=> x.testResponses
  .reduce((sum,test)=>{ if (tests.includes(test.testId)) {sum = sum + (test.registrationFee - (codeAccepted ? 100 : 0))}
 return sum},0));

   /*if (formValues.country==='Ukraine') {
     setTotalPrice(0);
   }
   else
   {*/
     setTotalPrice(tr[0]);
   /*console.log(tr[0]);*/}
   //};
   /*console.log(selectedTests);

   console.log(newCategories.filter((section=> (section.name===formValues.section))).map(x=> x.testResponses
   .map((test,i)=>{ if (selectedTests.includes(test.testId))  {return test.testName} else return " "})).toString().replaceAll(","," ").replace(/\s+/g, ' '));
*/
   //console.log(selectedTests.map(test=>{if test.testId})

  const validateSubmit = async (e) => {

    e.preventDefault();
    setLoadingmessage("Validating Competitor...Please Wait...");
    setIsLoading(true);//console.log("LOAD true");

    if(validate(formValues,photofiles)) {
      let formatedDateofBirth= moment(formValues.dateofbirth).format('YYYY-MM-DD');
      let validationError=[];
      const COMP_API_URL = process.env.REACT_APP_BACKEND_URL+"/services/competitorValidation";

      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'plain/text');
      const params={code:formValues.code, name:formValues.fname,lastName:formValues.lname,country:formValues.country,email:formValues.email,dateOfBirth:formatedDateofBirth,testId:selectedTests};
      const queryString = new URLSearchParams(params).toString();//console.log(queryString);

      fetch(COMP_API_URL+"?"+queryString)
        .then(response => response.json())
        .then(data =>
          {if(data.validCompetitorRegistration) {setValidated(true);/*console.log("True");*/ handleSubmit();}
            else {
              validationError.push({name:"Competitor Validation Error",value:  data.errorMessage});
              setFormErrors(validationError);
              setIsLoading(false);//console.log("LOAD false");
              //window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
              // const alertPosition = alertRef.current.offsetTop;
              // console.log(alertPosition);
              // window.scrollTo({ top: alertPosition, behavior: 'smooth' });

              // const formContainer = document.getElementById('formContainer');
              // const alertPosition = alertRef.current.offsetTop - formContainer.offsetTop;
              // console.log(alertPosition,formContainer.offsetTop);
              // window.scrollTo({ top: alertPosition, behavior: 'smooth' });
              setTimeout(() => {
                     window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
                   }, 0);

            };
      })
        .catch(err=>{ setIsLoading(false);alert("Error contacting server. Check your connection and try again later. " + err );})
    }
    else { setIsLoading(false);}
  }

const handlePay = (e)=>{
//setIsLoading(true);
if(!isPay){
//  setIsLoading(true);
  setIsPay(true);}
else {e.preventDefault();}
}

  const handleSubmit = async(e) => {
    setLoadingmessage("Commiting Registration...Please wait...");
    let formatedDateofBirth= moment(formValues.dateofbirth).format('YYYY-MM-DD');
    let upload_number_per_section=0;
    let total_price_after_discount = (payDiscount&&formValues.payDiscountCode===process.env.REACT_APP_DISCOUNT_PAYMENT_CODE)?totalPrice/2:totalPrice;
    const form = document.getElementById('myForm');

    //console.log((payDiscount&&formValues.payDiscountCode===process.env.REACT_APP_DISCOUNT_PAYMENT_CODE)?totalPrice/2:totalPrice);
    //setIsLoading(true);
    if(!isSubmit) { //Submit only once!
    setIssubmit(true);
  //console.log("Try Submit");

    const API_URL = process.env.REACT_APP_BACKEND_URL+"/services/registrationwrite";

   const myHeaders = new Headers();
    myHeaders.append('Content-Type', 'text/plain');

    // console.log(  JSON.stringify({        president:formValues.president,orgname:formValues.orgname,fname: formValues.fname,
    //         lname: formValues.lname,country:formValues.country,code:formValues.code,
    //         email:formValues.email,phone:formValues.phone,section:formValues.section,totalPrice:total_price_after_discount,testIds:selectedTests,pendingPayment:payLater,
    //         dateOfBirth:formatedDateofBirth,discount:payDiscount,allowPhoto:allowChecked
    //   }));
    const options = {
      method: 'POST',
      body:
      JSON.stringify({
        president:formValues.president,orgname:formValues.orgname,fname: formValues.fname,
        lname: formValues.lname,country:formValues.country,code:formValues.code,
        email:formValues.email,phone:formValues.phone,section:formValues.section,totalPrice:total_price_after_discount,testIds:selectedTests,pendingPayment:payLater,
        dateOfBirth:formatedDateofBirth,discount:payDiscount,allowPhoto:allowChecked
      }),
      myHeaders
    };


        //  console.log(options);

    let res = await fetch(API_URL,options)
    .then( response => {    if(!response.ok) throw new Error(response.status);
       else return response.json();})

    .then( async d=>{

      setregid(d);
        //console.log(formValues.idfile.name.split('.').pop());
        //Upload diploma + id compulsory files.
        let up1 = await uploadFile3(formValues.idfile,"idfile"+"."+formValues.idfile.name.split('.').pop().toLowerCase(),d);
        /*Diploma file upload*/
        let up2 = await uploadFile3(formValues.diplomafile,"diplomafile"+"."+formValues.diplomafile.name.split('.').pop().toLowerCase(),d);
        //No diploma file needed now.
        //let up2=true;
        //console.log(up1,up2);
        //  if (up1 && up2) setIsLoading(false);
        let u1=true;
        let u2=true;
        let u3=true;
        let u4=true;

          if (photofiles.length >0){//There are some files to upload
            upload_number_per_section=photofiles[0].photonumber;
            for(let i=0; i<photofiles.length;i++){
              u1 = u1&& await uploadFile3(photofiles[i].frontfile,"photo1"+"."+photofiles[i].frontfile.name.split('.').pop().toLowerCase(),d+"/"+"Test"+photofiles[i].id);
              //console.log(i+"Submited u1="+u1);
              if (photofiles[i].sidefile)
                {u2 = u2 && await uploadFile3(photofiles[i].sidefile,"photo2"+"."+photofiles[i].sidefile.name.split('.').pop().toLowerCase(),d+"/"+"Test"+photofiles[i].id);
              //  console.log(i+"Submited u2="+u2);
              }
              if (photofiles[i].sidefile3)
                {u3 = u3 && await uploadFile3(photofiles[i].sidefile3,"photo3"+"."+photofiles[i].sidefile.name.split('.').pop().toLowerCase(),d+"/"+"Test"+photofiles[i].id);
            //  console.log(i+"Submited u2="+u2);
                }
              if (photofiles[i].sidefile4)
                {u4 = u4 && await uploadFile3(photofiles[i].sidefile4,"photo4"+"."+photofiles[i].sidefile.name.split('.').pop().toLowerCase(),d+"/"+"Test"+photofiles[i].id);
              //  console.log(i+"Submited u2="+u2);
                }

          }
        }

          //DONE: include u1,u2 in case upload photofiles.length >0
          //  console.log(up1,up2,u1,u2,u3,u4,photofiles.length);
          //console.log(up1,up2,u1,u2,photofiles.length,((up1 && up2) &&(photofiles.length===0||(photofiles.length ===1 &&u1)||(photofiles.length ===2 &&(u1&&u2))) ) );
          //console.log(up1,up2,u1,u2,upload_number_per_section,photofiles.length,((up1 && up2) &&(upload_number_per_section===0||(upload_number_per_section ===1 &&u1)||(upload_number_per_section ===2 &&(u1&&u2))) ) );
        //  if ((up1 && up2) &&(photofiles.length===0||(upload_number_per_section ===1 &&u1)||(upload_number_per_section ===2 &&(u1&&u2))) ) { //Upload successfull

            if ((up1 && up2) &&(photofiles.length===0||(upload_number_per_section >=1 &&u1&&u2&&u3&&u4)) ) { //Upload successfull

            setLoadingmessage("Commiting Registration...Please wait...");
            const COMP_API_URL = process.env.REACT_APP_BACKEND_URL+"/services/uploadedPhotos";

            const myHeaders = new Headers();
            myHeaders.append('Content-Type', 'plain/text');

            const params={registrationId:d,successfulUpload:up1};
            const queryString = new URLSearchParams(params).toString();
            //console.log(queryString);

            let res2 = await fetch(COMP_API_URL+"?"+queryString,     {
                   method: 'post',
                 })
              .then(response => response.json())
              .then(data =>
                {//Upload Success
                  setSuccessUpload(true);
                  setIsLoading(false);
                  if(!payLater) document.getElementById('myForm').submit();
                })
              .catch(err=>{
                setIsLoading(false);
                alert("Error while uploading files. Check your connection and submit your registration again. " + err );
              })
          }//Upload failed
          else {
            //console.log("Upload FAILED");
            setIsLoading(false);
            alert ("Error while uploading files. Check your connection and submit your registration again. Cannot upload.");setIsLoading(false);}
    })
    .catch(err=>{
      setIsLoading(false);
      alert("Error commiting registration. Check your connection and submit your registration again. " + err);
    })

   }
   else {e.preventDefault(); setIsLoading(false);}
 }


  const validate=(values,photos) => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    //const phoneregex =^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$;
    //console.log(formValues,formValues.country.length);
    const errors=[];
    //console.log(moment().diff(moment(formValues.dateofbirth), "years")>100);

    values.email=values.email.replace(/^\s+|\s+$/g, '').replace(/\s\s+/g, ' ');
    values.lname=values.lname.replace(/^\s+|\s+$/g, '').replace(/\s\s+/g, ' ');
    values.fname=values.fname.replace(/^\s+|\s+$/g, '').replace(/\s\s+/g, ' ');

    //console.log(formValues.code==="");
      if(!values.email){
              errors.push({name:'email',value:"Email is required!"});
              //push({id: 4, name: "Mehul"})
        }
      if (!regex.test(values.email)){
              errors.push({name:'email',value: "Not a valid email format!"});
          }
    //  if (!values.president)
    //        {errors.push({name:'president',value:"OMC Member Country President field is required!"});}
    //  if (!values.orgname)
    //        {errors.push({name:"orgname",value:"Member country Organization name is required!"});}
  //    if (!values.code || values.code.length>10)
  //      {errors.push({name:"code",value:"Country Organization Code is required!"});}
    if (storedCode!==formValues.code)
      {
        errors.push({name:"storedCode",value:  "Organisation code / Country name mismatch. Please enter a correct one!"});
        setFormValues({ ...formValues,code:"",president:"",orgname:"",country:""});
        setStoredCode("");
        setCodeValid(false);
        setCodeAccepted(false);
        //Disselect tests, so discount calculates again.
        checkedState.fill(false);
        //Empty photofiles. Only one category is alllowed
        setPhotoFiles([]);
        setTotalPrice(0);
      }
      if (formValues.code!=""&&storedCode===formValues.code && storedCountry!=formValues.country)
        {
          errors.push({name:"storedCountry",value:  "Organisation code / Country name mismatch. Please enter a correct one!"});
          setFormValues({ ...formValues,code:"",president:"",orgname:"",country:""});
          setStoredCode("");
          setStoredCountry("");
          setCodeValid(false);
          setCodeAccepted(false);
          //Disselect tests, so discount calculates again.
          checkedState.fill(false);
          //Empty photofiles. Only one category is alllowed
          setPhotoFiles([]);
          setTotalPrice(0);
        }

      if (!values.country)
        {errors.push({name:"country",value:"Country is required!"});}
      if (!values.fname || values.fname.length>100)
        {errors.push({name:"fname",value:"First Name is Required!"});}
      if (!values.lname || values.lname.length>100)
        {errors.push({name:"lname",value:"Last Name is Required!"});}
      if (values.phone.length<8 || values.phone.length>20)
        {errors.push({name:"phone",value: "Enter a valid phone!"});}
      if (values.idfile.length===0)
          {errors.push({name:"idfile",value: "Enter an id File!"});}
      if (!isChecked)
          {errors.push({name:"isChecked",value: "You have to agree on Terms&Conditions to proceed!"});}
      /*Diploma file validation*/
       if (values.diplomafile.length===0)
           {errors.push({name:"diplomafile",value:  "Enter a Competitor Photo for Diploma!"});}
      if (!values.dateofbirth||values.dateofbirth=== undefined||!moment(formValues.dateofbirth).isValid()||moment().diff(moment(formValues.dateofbirth), "years")>110)
          {errors.push({name:"dateofbirth",value:  "Enter a valid date of birth!"});}
      if (photos && photos.length) //user must upload 1 or 2 photos for every selected test
        {
            //console.log(photos);
            photos.map((test) => {

              if((test.frontfile==='' && test.photonumber>=1 )|| (test.sidefile==='' && test.photonumber>=2)
              || (test.sidefile3==='' && test.photonumber>=3)|| (test.sidefile4==='' && test.photonumber>=4))

                //errors.push({name:"photoerror",value:"Please enter "+ test.photonumber +" photos for online section "+formValues.section});
                //errors.push({name:"photoerror",value:"Please enter "+ test.photonumber +" photos for online section "+formValues.section+" test "+test.testName});
                errors.push({
                name: "photoerror",
                value: `Please enter ${test.photonumber} ${test.photonumber === 1 ? " photo for online section " +formValues.section+" test "+test.testName: " photos for online section "+formValues.section+" test "+test.testName}`
                });





              })

    //    newCategories.filter((section=> (section.name===formValues.section))).map(x=> x.testResponses
    //                      .map((tr,i)=>{ if (tr.testId===)  {return tr.testName} else return " "})).toString().replaceAll(","," ").replace(/\s+/g, ' ');

        }
        //console.log(process.env.REACT_APP_PENDING_PAYMENT_CODE);
        //console.log(process.env.REACT_APP_DISCOUNT_PAYMENT_CODE);


      if (payLater&&(values.payLaterCode!==process.env.REACT_APP_PENDING_PAYMENT_CODE))
            errors.push({name:"payLaterCode",value:  "Not a valid special code A"});

      if (payDiscount&&(values.payDiscountCode!==process.env.REACT_APP_DISCOUNT_PAYMENT_CODE))
              errors.push({name:"payDiscountCode",value:  "Not a valid special code B"});




    //console.log(process.env.REACT_APP_DISCOUNT_PAYMENT_CODE+" "+values.payLaterCode, payLater,payDiscount,formValues.specialCode);

    //  if (payLater&&values.payLaterCode!==process.env.REACT_APP_DISCOUNT_PAYMENT_CODE)
    //        {errors.push({name:"payLaterCode",value:  "Not a valid code for later payment"});}

      /*console.log(errors);*/
      setFormErrors(errors);

      if(errors.length===0)    return true;
      else return false;
  }

  return(

      <div id="formContainer" className="create">

      <h1>Registration Form - Global Artist of the Year</h1>
        <Alert severity="warning" variant="outlined"><b>Registration will be rejected if the information below is not complete.</b></Alert>
        {!formValues.code&&<Alert severity="info" variant="outlined">
        <b>Please enter your organization code before filling in the form and save $100 for each test selected.<br/>
         <Typography sx={{textDecoration: 'underline', fontSize: '15px', color: 'red' }}>
         Contact your organization president to provide you with the corresponding organization code.</Typography>
        If no Country Code is entered, then pay a regular $300 fee per test selected
        </b></Alert>}


        <form id="myForm" action = {process.env.REACT_APP_BACKEND_URL+"/services/registration?regid="+registrationId} method="POST">
        <div className="code">
        <Stack direction="row" justifyContent="left" alignItems="left" spacing={4}>
        <label htmlFor= "code">Enter code to save $100</label>
        <TextField disabled={validated||isLoading}
        type="text" required
        style ={{width: '40%'}}
        name= "code" id= "code"
        value={formValues.code}
        onChange={codeChange}
        onKeyPress={handleKeypress}

        />
        <Button disabled={validated||isLoading} id="codebutton" color="primary" variant="contained" onClick={handleClickOpen}  autoFocus>
        Enter Code
        </Button>
        </Stack>
      </div>

      <Dialog
        open={open}
        //onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      {formValues.orgname===""&&<div>
      <DialogTitle id="alert-dialog-title">
        {codeValid?"":"Not a  valid code"}
      </DialogTitle>
        <Alert severity="error">{codeValid?"Please ask your president for the right information and enter proper code.":"Please enter a valid code!"}</Alert>
          <DialogActions>
            <Button onClick={handleCloseNo}>Close</Button>  </DialogActions></div>
      }
        { formValues.orgname!==""&&<div>
        <DialogTitle id="alert-dialog-title">
          {"Accept organization, president for code: "+formValues.code+" ?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          {"Organization name: "+formValues.orgname}<br />
          {"President name: " + formValues.president}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDis}>Disagree</Button>
          <Button onClick={handleCloseYes} autoFocus>
            Agree
          </Button>
        </DialogActions></div>
      }
      </Dialog>


        <Box ref={secondBoxRef} tabIndex="-1"
        sx={{ display: 'flex', flexDirection: 'column', gap: 2 ,border: '2px solid #ddd', padding: '10px',
        opacity: validated||isLoading ? 0.5 : 1 }} disabled={validated||isLoading} >

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 ,border: '2px solid #ddd', padding: '10px',
        opacity: 0.5 }}  className="notreqfields">
          <label htmlFor="president">OMC Member Country President</label>
          <TextField
            disabled={!codeAccepted||validated||isLoading}
            type="text"
            variant="outlined"
            required
            name="president" id="president"
            value={codeAccepted?formValues.president:""}
            onChange={handleChange}
            style ={{width: '100%'}}
            InputProps={{
               readOnly: true,
             }}
             sx={{
                '& .MuiFilledInput-root': {
                  backgroundColor: 'rgba(128, 128, 128, 0.1)', // Grey color with 50% opacity
                },
              }}
          />
          <label htmlFor="orgname">Member country organization name</label>
          <TextField
            disabled={!codeAccepted||validated||isLoading}
            type="text"
            variant="outlined"
            required
            name="orgname" id="orgname"
            style ={{width: '100%'}}
            InputProps={{
               readOnly: true,
             }}
             sx={{
     '& .MuiFilledInput-root': {
       backgroundColor: 'rgba(128, 128, 128, 0.1)', // Grey color with 50% opacity
     },
   }}
            value={codeAccepted?formValues.orgname:""}
            onChange={handleChange}
              />
              </Box>
              <div className="reqfields">

              {false&&countries.length &&
                 <FormControl fullWidth >
                     <label htmlFor="country">Country</label>
                     <TextField
                         disabled={ validated || isLoading}
                         style={{width: '100%'}}
                         InputProps={{
                             readOnly: codeAccepted,
                         }}
                         inputProps={{
                             style: {textTransform: 'uppercase'},
                             autoComplete: "name" // Add this line
                         }}
                         name="country"
                         id="country"
                         value={formValues.country}
                     >
                         {countries.map(item => {
                         })}
                     </TextField>
                 </FormControl>
              }

              {countries.length > 0 && (
      <FormControl size="small" fullWidth>
        <label htmlFor="country">Country</label>
        <Select
          disabled={validated || isLoading ||codeAccepted}
          style={{ width: '100%' }}
          inputProps={{
            style: { textTransform: 'uppercase' },
            name: "country",
            id: "country",
            autoComplete: "off", // Explicitly set autocomplete to "off"
            value: formValues.country,
          }}
          onChange={handleChange}
        >
          {countries.map((item) => (
            <MenuItem key={item.id} value={item.name}>
              {item.name.toUpperCase()}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    )}


            <label htmlFor="fname">Competitor First Name</label>
            <TextField type="text" required
            error={formErrors.some(error => error.name === "fname")}
            disabled={validated||isLoading}
            style ={{width: '100%'}}
            name = "fname" id = "fname"  value={formValues.fname}
            inputProps={{style: {textTransform: 'uppercase'}}}
            onChange={handleChange}/>

            <label htmlFor="lname">Competitor Last Name</label>
            <TextField type="text" required
              error={formErrors.some(error => error.name === "lname")}
            disabled={validated||isLoading}
            style ={{width: '100%'}}
            inputProps={{style: {textTransform: 'uppercase'}}}
            name = "lname" id = "lname" value={formValues.lname}
            onChange={handleChange}
            />
            <div style={{ textAlign: 'left' }}>
            <label>Competitor Birth Date</label>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
               <DesktopDatePicker
                   disableFuture
                   name="dateofbirth"
                   minDate={new Date("1920-01-01")}
                   views={['year', 'month', 'day']}
                   openTo="year"
                   style={{ flex: 1 }}
                   disabled={ validated || isLoading}
                   value={formValues.dateofbirth}
                   error={formErrors.some(error => error.name === "dateofbirth")}
                   onOpen={(newValue) => {
                       setFormValues({ ...formValues, ['dateofbirth']: new Date(1990, 0, 1) });
                   }}
                   onChange={(newValue) => {
                       setFormValues({ ...formValues, ['dateofbirth']: newValue });
                   }}
                  //  renderInput={(params) => <TextField    id="dueDate"      error={formErrors.some(error => error.name === "dueDate")}
                  // required  style ={{width: '50%'}} readOnly  {...params} />}
                   slotProps={{
                     textField: {
                       inputProps: {
                         readOnly: true,
                       },
                      // helperText: formErrors.some(error => error.name === "dateofbirth") ? "Error message" : null,
                       //classes: formErrors.some(error => error.name === "dateofbirth") ? classes.error : {},
                       error: formErrors.some(error => error.name === "dateofbirth"),

                     }
                   }}

               />
            </LocalizationProvider>
            </div>
            <label htmlFor="email">Competitor email</label>
            <TextField type="email" required
            error={formErrors.some(error => error.name === "email")}

            disabled={validated||isLoading}
            style ={{width: '100%'}}
            name= "email"  id= "email" value={formValues.email}
            onChange={handleChange}
            />
            {/*<label>Competitor phone</label>
            <TextField type ='text' required name='phone'
              disabled={!codeAccepted||validated||isLoading}
            error={formErrors.some(error => error.name === "phone")}
            style ={{width: '100%'}}
             value={formValues.phone}
            onChange={handleChange}
            />*/}
            <div style={{ textAlign: 'left' }}>

            <label>Competitor phone</label>
            <PhoneInput required name="phone" key="phone"
            countryCodeEditable variant="outlined"
            color="secondary"
            inputStyle={inputStyle}
              disabled={validated||isLoading}
              name="phone"
            value={formValues.phone}
            onChange={(newValue) => {
             setFormValues({ ...formValues,['phone']: newValue});
            }}
            />
            </div>
              </div>

              <div>
            {/*}<label> Competitor ID Card or Driver Lisence or Passport     </label>
              <input key="idfile" type="file" onChange={handleChange}  id="idfile"  name = "idfile" className="idfile" accept={types} />*/}
              <input style={{ display: 'none' }}  key="idfile" type="file" onChange={handleChange} id="idfile"  name = "idfile" accept={types}/>

              <label style={{ fontWeight: 'bold' }} htmlFor="idfile">Competitor ID Card or Driver Lisence or Passport<span className="reqfiles">*</span><br/>
                 <Button variant="contained" color="secondary" size="small"   disabled={validated||isLoading} component="span">
                   {formValues.idfile?"Change Selection":"Upload file"}
                 </Button>
                 {formValues.idfile&&
                 <Card sx={{ maxWidth: 350 }}>
                 <CardMedia
                   component="img"
                   height="194"
                   image={URL.createObjectURL(formValues.idfile)}
                   alt="Identity Photo"
                 />
               </Card>}
                </label>
              {<div>
                <Stack direction="column" className="diplomaphoto">
              <input style={{ display: 'none' }}  key="diplomafile" type="file" onChange={handleChange} id="diplomafile"  name = "diplomafile" accept={types}/>
              <label style={{ fontWeight: 'bold' }} htmlFor="diplomafile">Competitor Photo for Diploma<span className="reqfiles">*</span> <br/>

                 <Button variant="contained" color="secondary" size="small"   disabled={validated||isLoading} component="span">
                 {formValues.diplomafile?"Change Selection":"Upload file"}
               </Button>
              {formValues.diplomafile&&
              <Card sx={{ maxWidth: 350 }}>
              <CardMedia
                component="img"
                height="194"
                image={URL.createObjectURL(formValues.diplomafile)}
                alt="Diploma Photo"
              />
              </Card>}
              </label>
              <label>
              <Checkbox color="default"
                        id="allow"
                        checked={allowChecked}
                        onChange={allowCheckHandler}/>
              I allow OMC to publish my photo </label>
              </Stack>
              </div>}
              </div>
              {!isCountryLoading&& (newCategories.length>0)&&<Box  className="uploadphotos">
            <label>Section</label>

              <FormControl key={"fc_"+formValues.section} variant="standard" component="fieldset">
              <RadioGroup  key={"tf_"+formValues.section}    size="small" disabled={validated||isLoading||isCountryLoading}
               id="section" name="section"  value={formValues.section}
                    onChange={handleChange}>

            {!isCountryLoading&& (newCategories.length>0)&&newCategories.map((item,i) => {
            //console.log(formValues.section,item.name);

            return (

            <FormControlLabel
                  control={<Radio />}
                  key={"mi_" + i}
                  label={
                    <>
                     {item.name}
                     {item.name===formValues.section&&item.testResponses.map((test, j) => (
                        <div key={j} className="uploadphotoscheck">
                        <label  key={i}   htmlFor={`custom-checkbox-${i}`}>
                          <Checkbox color="default"
                            id={`custom-checkbox-${j}`}
                            label={test.testName}
                            value={test.testId}
                            checked={checkedState[test.testId]}
                            onChange={(event) => handleCheckTest(event,item.name ,test.testId,test.uploadRegistrationPhotos,test.combinationCategory)}
                          />{`${test.testName} ${test.combinationCategory ? `(${test.combinationCategory})` : ''} (${test.registrationFee - (codeAccepted ? 100 : 0)}$)`}
                          </label>
                          {/*Photo1*/
                            checkedState[test.testId]&& (test.uploadRegistrationPhotos>0)&&

                          <Stack  key={'div1_${i}'} spacing={1}>
                          <input  style={{ display: 'none' }} type="file" key={`frontfile_${i}`} name = "frontfile"
                          onChange={handleChangeFile(test.testId,item.name,test.uploadRegistrationPhotos)}
                          id={`frontfile_${i}`}  accept={types} required/>
                            <label htmlFor={`frontfile_${i}`}>
                          {photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.frontfile})[0]===''&&
                          <Alert variant="outlined" severity="info">Please upload your photo. Ensure it's no larger than 10MB and is in a supported format: JPEG, JPG, PNG, TIFF, or GIF.</Alert>}
                          {!(photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.frontfile})[0]==='')&& <Alert variant="outlined" severity="success">Photo selected!</Alert>}
                              <Card sx={{ maxWidth: ((
                              photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.testName})[0]==="Fantasy Nail"
                              || photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.testName})[0]==="Creative Nail Design"
                            )
                              ?500:250)}}>
                            {/*Card header for nails not painting box test*/
                              photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.frontfile})[0]===''
                            &&photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.testName})[0]!=="Nail Painting-Box"
                            &&
                            <CardHeader
                            titleTypographyProps={{
                              fontSize: 18,
                            }}
                              title={"Suggested "+ (formValues.section==="Senior Ladies Nails"?"hand":"model") +" position for "+photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.testName})[0]}
                              //title={"Participation photo 1"}
                               //subheader="September 14, 2016"
                             />}
                             {photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.frontfile})[0]!==''&&<CardHeader
                             titleTypographyProps={{
                               fontSize: 18,
                             }}
                               //title={"Suggested "+ (formValues.section==="Senior Ladies Aesthetics"?"model":"hand") +" position for "+formValues.section}
                               title={"Participation photo 1"}
                                //subheader="September 14, 2016"
                              />}
                              {/*Card Photo1 for Tests except from Nail Painting-Box/ Eyebrow Shaping & Tinting/Nails Salon Manicure Design*/
                                photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.testName})[0]!=="Nail Painting-Box"
                              &&photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.testName})[0]!=="Eyebrows Shaping And Tinting"
                              &&photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.testName})[0]!=="Nails Salon Manicure Design"
                              &&<CardMedia
                                component="img"
                                height= "300"
                                src={photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.frontfile})[0]!==''?
                                URL.createObjectURL(photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.frontfile})[0]):"/"+`${formValues.section}`+"/Photo1.jpg"}
                                                         //image='front.jpg'
                               alt="Photo1"
                              />}
                          </Card>
                          <Button variant="contained" color="secondary" size="small"   disabled={validated||isLoading} component="span">
                          {photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.frontfile})[0]?"Change Selection":"Upload file"}
                          </Button>
                          </label>
                          </Stack>
                            }
                        </div>
                      ))}
                    </>
                  }
                  value={item.name}
                />


          );


            })}
            </RadioGroup></FormControl>
            </Box>}
            {
              false&&!isCountryLoading&&(newCategories.length>0) && newCategories.map((section,j) => (

              <div key={j} className = "uploadphotos" >

                <ul  key={j}  className="testlist">
                  { section.testResponses&&section.testResponses.map((test,i) => (

                  <li key={"ch"+j} className="unordered-list">
                    <div  key={i} className="list-item">
                      <label  key={i}   htmlFor={`custom-checkbox-${i}`}>

                        <Checkbox color="default"
                          id={`custom-checkbox-${j}`}
                          label={test.testName}
                          value={test.testId}
                          checked={checkedState[test.testId]}
                          onChange={(event) => handleCheckTest(event,section.name ,test.testId,test.uploadRegistrationPhotos,test.combinationCategory)}
                        />{`${test.testName} ${test.combinationCategory ? `(${test.combinationCategory})` : ''} (${test.registrationFee - (codeAccepted ? 100 : 0)}$)`}
 </label>

                        {/*Photo1*/
                          checkedState[test.testId]&& (test.uploadRegistrationPhotos>0)&&

                        <Stack  key={'div1_${i}'} spacing={1}>
                        <input  style={{ display: 'none' }} type="file" key={`frontfile_${i}`} name = "frontfile"
                        onChange={handleChangeFile(test.testId,section.name,test.uploadRegistrationPhotos)}
                        id={`frontfile_${i}`}  accept={types} required/>
                          <label htmlFor={`frontfile_${i}`}>
                        {photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.frontfile})[0]===''&&
                        <Alert variant="outlined" severity="info">Please upload your photo. Ensure it's no larger than 10MB and is in a supported format: JPEG, JPG, PNG, TIFF, or GIF.</Alert>}
                        {!(photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.frontfile})[0]==='')&& <Alert variant="outlined" severity="success">Photo selected!</Alert>}
                            <Card sx={{ maxWidth: ((
                            photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.testName})[0]==="Fantasy Nail"
                            || photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.testName})[0]==="Creative Nail Design"
                          )
                            ?500:250)}}>
                          {/*Card header for nails not painting box test*/
                            photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.frontfile})[0]===''
                          &&photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.testName})[0]!=="Nail Painting-Box"
                          &&
                          <CardHeader
                          titleTypographyProps={{
                            fontSize: 18,
                          }}
                            title={"Suggested "+ (formValues.section==="Senior Ladies Nails"?"hand":"model") +" position for "+photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.testName})[0]}
                            //title={"Participation photo 1"}
                             //subheader="September 14, 2016"
                           />}
                           {photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.frontfile})[0]!==''&&<CardHeader
                           titleTypographyProps={{
                             fontSize: 18,
                           }}
                             //title={"Suggested "+ (formValues.section==="Senior Ladies Aesthetics"?"model":"hand") +" position for "+formValues.section}
                             title={"Participation photo 1"}
                              //subheader="September 14, 2016"
                            />}
                            {/*Card Photo1 for Tests except from Nail Painting-Box/ Eyebrow Shaping & Tinting/Nails Salon Manicure Design*/
                              photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.testName})[0]!=="Nail Painting-Box"
                            &&photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.testName})[0]!=="Eyebrows Shaping And Tinting"
                            &&photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.testName})[0]!=="Nails Salon Manicure Design"
                            &&<CardMedia
                              component="img"
                              height= "300"
                              src={photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.frontfile})[0]!==''?
                              URL.createObjectURL(photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.frontfile})[0]):"/"+`${formValues.section}`+"/Photo1.jpg"}
                                                       //image='front.jpg'
                             alt="Photo1"
                            />}
                        </Card>
                        <Button variant="contained" color="secondary" size="small"   disabled={validated||isLoading} component="span">
                        {photofiles.filter(ph=>(ph.id===test.testId)).map(filt=>{return filt.frontfile})[0]?"Change Selection":"Upload file"}
                        </Button>
                        </label>
                        </Stack>
                          }

                  </div>
                  </li>

                ))
              }
                </ul>
                </div>
              ))
            }

            <Stack direction="column" className="agree">
            <label>
            <Checkbox color="default"
                      id="agree"
                      checked={isChecked}
                      onChange={checkHandler}/>
            I, the undesigned, have read and approve this discharge and release </label>
            <a href="https://omchairworld.com/terms-and-conditions/" target="_blank" rel="noopener noreferrer">Terms&Conditions</a>
            </Stack>

</Box>
          {<Alert severity="info" variant="outlined"><b>If you do not have code A or B go directly to submit registration!</b></Alert>}
          <Stack sx={{ flexDirection: 'row' ,border: '2px solid #ddd' ,opacity: validated||isLoading ? 0.5 : 1 }}
           direction="row" spacing={6}>
          <span>
          <Checkbox color="secondary"
                    id="paylater"
                    checked={payLater}
                    onChange={payLaterChange}
                    disabled = {validated||isLoading ? true : false}
                    />
            If you have code A press here and enter here:</span>

          {
          <TextField type ='password' required name="payLaterCode"
        variant="outlined"
           error={formErrors.some(error => error.name === "payLaterCode")}
           value={formValues.payLaterCode}
           disabled = {!payLater||validated||isLoading ? true : false}
            InputProps={{ className: classes.input1 }}
            sx={{

    width: { sm: 100, md: 100 }
}}
          onChange={payLaterCodeChange}/>
        }

        </Stack>

        <Stack sx={{ flexDirection: 'row' ,border: '2px solid #ddd' ,
        opacity: validated||isLoading ? 0.5 : 1 }}
         direction="row" spacing={6}>        <span>
        <Checkbox color="primary"
                  id="paydiscount"
                  checked={payDiscount}
                  onChange={payDiscountChange}
                  disabled = {validated||isLoading ? true : false}
                  />
        If you have code B press here and enter here:</span>

        {
        <TextField color='secondary' type ='password' required name="payDiscountCode"
        variant="outlined"
         error={formErrors.some(error => error.name === "payDiscountCode")}
         value={formValues.payDiscountCode}
         disabled = {!payDiscount||validated||isLoading ? true : false}
          InputProps={{ className: classes.input }}
          sx={{
        width: { sm: 100, md: 100 }
        }}
        onChange={payDiscountCodeChange}/>
        }

        </Stack>
        <section>
        <div className="description">
        <Paper><b>Total Price is {(payDiscount&&formValues.payDiscountCode===process.env.REACT_APP_DISCOUNT_PAYMENT_CODE)?totalPrice/2:totalPrice}$</b></Paper>
        </div>
        </section>

          {formErrors.length>0&&<div ref={alertRef} className="errors">
          <Alert  variant="outlined" severity="error">
          {formErrors.map((error,i)=>(<p key={i}>{error.value}</p>))}
          </Alert></div>}

          {false&&successUpload&&!isLoading&&!payLater&&validated&&<div className="proceed">
          <Alert variant="outlined" severity="warning" > <b>Your registration will not be completed unless you pay the total fee.
          Proceed to payment to complete your registration!</b></Alert>
          </div>}

          {successUpload&&!isLoading&&payLater&&registrationId&&validated&&
            <div className="SuccessPage"
            style={{ width: "100%", display: "flex", justifyContent: "flex-end" ,  padding: "15px"}}>
            <CheckCircleIcon style={{ color:"green", fontSize:"40px" }}/>
              <Paper><span className="orangespan">Registration is not complete until you arrange your payment.<br/></span> An email was sent to <b>{formValues.email}</b>.<br />Please check your spam/junk folder also.<br />The details of your registration are:<br/>
              <p><b>First Name: </b> {formValues.fname}</p>
                <p><b>Last Name: </b>{formValues.lname}</p>
                <p><b>Total Fee: </b>{(payDiscount&&formValues.payDiscountCode===process.env.REACT_APP_DISCOUNT_PAYMENT_CODE)?totalPrice/2:totalPrice}$</p>
                <p><b>Participating in Section: </b>{formValues.section}</p>
    {
                <p><b>Tests: </b>{newCategories.filter((section=> (section.name===formValues.section))).map(x=> x.testResponses
                .map((test,i)=>{ if (selectedTests.includes(test.testId))  {return test.testName} else return " "})).toString().replace(/,/g," ").replace(/\s+/g, ' ')}</p>
              }
              {<p>
                {newCategories.filter((section=> (section.name===formValues.section))).map(x=> x.testResponses
                .map((sum,test)=>{ if (selectedTests.includes(test.testId))  return test.testName}))}
                        </p>}
                <p><b>Please save this information for future reference of your registration.</b></p>

                </Paper>
            </div>}

          {!isLoading&&!validated&&<div>
           <Button endIcon={!validated&&<UploadIcon/>}
            onClick = {validateSubmit} variant="contained"
            disabled={!((totalPrice>0 /*|| formValues.country==='Ukraine'*/) && !validated)}
            type="button" autoFocus >
             {!validated&&"Submit Registration"}
           </Button>
           </div>}

          {false&&successUpload&&!payLater&&!isLoading&&registrationId&&<div>
            <Button endIcon={validated && <CreditCardIcon/>} onClick = {handlePay} variant="contained"  disabled={isLoading/*isSubmit*/ } type="submit" autoFocus>
              "Pay now" {(payDiscount&&formValues.payDiscountCode===process.env.REACT_APP_DISCOUNT_PAYMENT_CODE)?totalPrice/2:totalPrice}$
            </Button>
            </div>}

          {(isCountryLoading||isLoading /*&&!isPay*/) &&
          <div className="circular" style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                        <CircularProgress thickness={1} size={150}/>
          <span style={{ justifyContent: "center", position: "fixed", top: "65%" }}>{loadingmessage}</span>
          </div>
          }
        </form>
      </div>
  );
}

export default CreateRegistration;

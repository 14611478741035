import React from "react";
import Navbar from './Navbar';
//import ParticipantList from './ParticipantList';
//import CreateRegistration from './CreateRegistration';
//import { useState,useEffect } from 'react';
import Alert from '@mui/material/Alert';


function App() {




  return (
    <div className="App">
        <div className="content">
        <Alert severity="error">Registrations are currently closed! </Alert>
        </div>
    </div>
  );
}

export default App;

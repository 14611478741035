import * as React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
//import { render } from 'react-dom';
import Navbar from './Navbar';
import CreateRegistration from './CreateRegistration';
import SuccessPage from './SuccessPage';
import CancelPage from './CancelPage';
import NotFoundPage from './NotFoundPage';
import PendingPayments from './PendingPayments';
import Login from "./Login";
import JurorLogin from "./JurorLogin";
import RegistrationsClosed from "./RegistrationsClosed";
import JurorTests from "./JurorTests";

import {useEffect} from "react";
//import {useParams} from 'react-router-dom';

function App() {
  document.title = "OMC Hairworld Registration";
  //const {section} = useParams();

//   const RedirectSite = () => {
//   window.location.href = "./RegistrationsClosed.html";
//   return <></>;
// };


  useEffect(() => {
  //const {section} = useParams();
  //console.log(section);
   },[]);

  return (
    <BrowserRouter>
<div className='App'>
<Navbar />
<div className="content">

      <Routes>
      <Route path="*" element={<NotFoundPage />} />
          <Route  path="/Registration" exact={true}  element={<RegistrationsClosed />} />
          <Route  path="/SuccessPage/:sessionid"   element={<SuccessPage />} />
          <Route  path="/CancelPage"   element={<CancelPage />} />
          <Route  path="/admin/Registrations" exact={true}  element={<PendingPayments />} />
          <Route  path="/NotFoundPage"  element={<NotFoundPage />} />
          <Route  path="/admin/Login" exact={true}  element={<Login />} />
            <Route  path="/admin/JurorLogin" exact={true}  element={<JurorLogin />} />
            <Route  path="/admin/JurorTests" exact={true}  element={<JurorTests />} />

      </Routes>
      </div>
</div>
</BrowserRouter>
  );
}
//render(<App />, document.querySelector('#app'));

export default App;
